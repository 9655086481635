// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advocacy-tsx": () => import("./../../../src/pages/advocacy.tsx" /* webpackChunkName: "component---src-pages-advocacy-tsx" */),
  "component---src-pages-browser-not-supported-tsx": () => import("./../../../src/pages/browser-not-supported.tsx" /* webpackChunkName: "component---src-pages-browser-not-supported-tsx" */),
  "component---src-pages-do-not-sell-confirmation-tsx": () => import("./../../../src/pages/do-not-sell-confirmation.tsx" /* webpackChunkName: "component---src-pages-do-not-sell-confirmation-tsx" */),
  "component---src-pages-do-not-sell-index-tsx": () => import("./../../../src/pages/do-not-sell/index.tsx" /* webpackChunkName: "component---src-pages-do-not-sell-index-tsx" */),
  "component---src-templates-advocacy-article-tsx": () => import("./../../../src/templates/advocacyArticle.tsx" /* webpackChunkName: "component---src-templates-advocacy-article-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */)
}

