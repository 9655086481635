import {observable, decorate, action} from 'mobx';

const ALERT_BAR_KEY = 'reuseplastic-alertbar-present';

const getAlertBarStatus = () => {
  return JSON.parse(localStorage.getItem(ALERT_BAR_KEY) || 'true');
};

const setAlertBarStatus = (status) => {
  localStorage.setItem(ALERT_BAR_KEY, JSON.stringify(status));
};

class GlobalStore {
  mobileNavOpen = false;
  showAlertBar = typeof window !== 'undefined' ? getAlertBarStatus() : false;
  alertBarHeight = null;

  closeMobileNavMenu = () => {
    this.mobileNavOpen = false;
  };

  toggleMobileNavMenu = () => {
    this.mobileNavOpen = !this.mobileNavOpen;
  };

  closeAlertBar = () => {
    this.showAlertBar = false;
    setAlertBarStatus(this.showAlertBar);
  };

  setAlertBarHeight = (height) => {
    this.alertBarHeight = height;
  };
}

decorate(GlobalStore, {
  mobileNavOpen: observable,
  showAlertBar: observable,
  alertBarHeight: observable,
  toggleMobileNavMenu: action,
  closeAlertBar: action,
  setAlertBarHeight: action,
  closeMobileNavMenu: action,
});

const store = new GlobalStore();

export default store;
