import {observable, decorate, action} from 'mobx';
import qs from 'query-string';

const getDiscoverCategory = () => {
  const parsed = qs.parse(window.location.search);
  return parsed.category ? parsed.category : '';
};

const getDiscoverTags = () => {
  const parsed = qs.parse(window.location.search);
  return parsed.tags ? new Set(parsed.tags.split(',')) : new Set();
};

class DiscoverStore {
  selectedCategory = typeof window !== 'undefined' ? getDiscoverCategory() : '';
  selectedTags = typeof window !== 'undefined' ? getDiscoverTags() : new Set();
  showTagsList = false;

  setCategory = (categorySlug: string) => {
    this.selectedCategory = categorySlug;
  };

  addTag = (tagSlug: string) => {
    this.selectedTags.add(tagSlug);
  };

  removeTag = (tagSlug: string) => {
    if (tagSlug === 'all') {
      return this.selectedTags.clear();
    }
    this.selectedTags.delete(tagSlug);
  };

  toggleTag = (tagSlug: string) => {
    const isActive = this.selectedTags.has(tagSlug);
    if (isActive) {
      this.removeTag(tagSlug);
    } else {
      this.addTag(tagSlug);
    }
  };

  toggleTagsList = () => {
    this.showTagsList = !this.showTagsList;
  };

  closeTagsList = () => {
    this.showTagsList = false;
  };
}

decorate(DiscoverStore, {
  selectedCategory: observable,
  selectedTags: observable,
  showTagsList: observable,
  setCategory: action,
  addTag: action,
  removeTag: action,
  toggleTag: action,
  toggleTagsList: action,
  closeTagsList: action,
});

const discoverStore = new DiscoverStore();

export default discoverStore;
