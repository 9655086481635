import wrapWithProvider from './src/utils/wrapWithProvider';
export const wrapRootElement = wrapWithProvider;


export const onRouteUpdate = ({ location, prevLocation }) => {
  const message = `Gatsby navigated from ${prevLocation ? prevLocation.pathname : null} to ${location.pathname}`;
  window.Sentry && window.Sentry.addBreadcrumb({
    category:  'gatsby navigation change',
    message,
  });
  const titleTag = document.querySelector('title')
  window.gtag && window.gtag('config', 'UA-164361054-1', {
    page_path: location.pathname,
    page_title: titleTag ? titleTag.innerText : null,
    location: window.location.href,
  });
  //Custom route change event
  window.dataLayer && window.dataLayer.push({
    event: 'route-change',
    from: prevLocation ? prevLocation.pathname : null,
    to: location ? location.pathname : null,
  });
}
