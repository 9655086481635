import React from 'react';
import {Provider} from 'mobx-react';
import store from '../store';
import discoverStore from '../store/discover';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({element}) => (
  <Provider store={store} discoverStore={discoverStore}>
    {element}
  </Provider>
);
